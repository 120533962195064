<template>
  <div>
    <!-- modal -->
    <b-modal
      ref="detail-modal"
      hide-footer
      size="xl"
      title="Using Component Methods"
    >
      <sales-detail-table
        v-if="selectedData!=null"
        :userid="selectedData.user_id"
        :target-year="targetYear"
        :target-month="target_month"
      />
    </b-modal>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            {{ title }}
          </b-card-title>
          <b-card-sub-title>  {{ data.total_sales }} TL / {{ data.total_targets }} TL</b-card-sub-title>
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">

          <b-form-group>

            <b-form-select
              v-model="target_month"
              :options="targetMonts"
              text-field="name"
              value-field="number"
            />
          </b-form-group>
        </div>
      <!--/ badge -->
      </b-card-header>

      <b-card-body>
        <b-button
          variant="outline-primary"
          class="mb-2"
          @click="showAllSalesDetail"
        >
          Tüm satış detaylarını göster
        </b-button>
        <b-table
          small
          :fields="fields"
          :items="items"
          responsive="sm"
          selectable

          select-mode="single"
          @row-selected="dataSelected"
        >

          <!-- A custom formatted column -->
          <template #cell(user_name)="data">
            {{ data.item.user_name+' - '+ data.item.user_surname }}
          </template>

          <!-- A custom formatted column -->
          <template #cell(complete_rate)="data">
            <b-progress

              max="100"
              :variant="data.item.color"
              striped
            >
              <b-progress-bar :value="data.value">
                <strong>{{ data.value.toFixed(2) }} %</strong>
              </b-progress-bar>

            </b-progress>
          </template>

          <template #cell(status)="data">
            <b-badge
              pill
              :variant="data.item.color"
            >
              {{ data.value }}
            </b-badge>
          </template>

          <!-- A virtual composite column -->
          <template #cell(sales)="data">
            {{ $staticParams.formatCurrency(data.value)+' '+data.item.currency_name }}
          </template>
          <template #cell(target)="data">
            {{ $staticParams.formatCurrency(data.value)+' '+data.item.currency_name }}
          </template>
          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BProgress, BProgressBar, BBadge, BFormGroup, BFormSelect, BModal, BButton,
} from 'bootstrap-vue'

import SalesDetailTable from './SalesDetailList.vue'

export default {
  components: {
    BCardHeader,
    BProgressBar,
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BCardSubTitle,
    BTable,
    BProgress,
    SalesDetailTable,
    BBadge,
    BModal,
    BFormGroup,
    BFormSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    monthTarget: {
      type: Number,
      default: 0,
    },
    targetYear: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      target_month: 0,
      fields: [
        { key: 'user_name', label: 'Ad Soyad' },
        // A regular column
        { key: 'complete_rate', label: 'Tamamlama Oranı' },
        { key: 'status', label: 'Durum' },
        // A regular column
        { key: 'sales', label: 'Satış' },
        // A virtual column made up from two fields
        { key: 'target', label: 'Hedef' },
      ],
      items: [
      ],
      selectedData: null,
    }
  },
  computed: {
    targetMonts() {
      const arr = [{ number: 0, name: 'Tüm Aylar' }, { number: 13, name: 'Bu Aya Kadar' }]
      this.$staticParams.monthNames.forEach(val => {
        arr.push(val)
      })
      return arr
    },
  },
  watch: {
    data() { this.makeData() },
    target_month() {
      this.makeData()
    },
  },
  created() {
    this.target_month = this.monthTarget
    if (this.monthTarget === -1) {
      const d = new Date()
      this.target_month = d.getMonth() + 1
    }

    this.makeData()
  },
  methods: {
    showAllSalesDetail() {
      this.selectedData = { user_id: 0 }
      this.$refs['detail-modal'].show()
    },
    dataSelected(item) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedData = item[0]
      // eslint-disable-next-line prefer-destructuring

      this.$refs['detail-modal'].show()
    },
    makeData() {
      const dataArr = []
      const currencyName = this.data.currency_name
      this.data.monthly_sales_and_targets.forEach(val => {
        const userData = {
          user_id: val.user_id,
          user_name: val.user_name,
          user_surname: val.user_surname,
          target: 0,
          sales: 0,
          status: '',
          complete_rate: 0,
          color: '',
          currency_name: currencyName,

        }
        val.target_list.forEach(val2 => {
          if (this.target_month === 13) {
            const d = new Date()

            if (val2.id <= d.getMonth() + 1) {
              userData.target += parseFloat(val2.target)
              userData.sales += parseFloat(val2.sales)
            }
          } else if (this.target_month !== 0) {
            if (val2.id === this.target_month) {
              userData.target += parseFloat(val2.target)
              userData.sales += parseFloat(val2.sales)
            }
          } else {
            userData.target += parseFloat(val2.target)
            userData.sales += parseFloat(val2.sales)
          }
        })
        if (userData.target > 0) {
          userData.complete_rate = (userData.sales / userData.target) * 100
          if (userData.complete_rate >= 100) {
            userData.color = 'success'
            userData.status = 'Hedef Tamamlandı'
          } else {
            userData.color = 'danger'
            userData.status = 'Hedef Tamamlanmadı'
          }
        }
        if (userData.target > 0) {
          dataArr.push(userData)
        }
      })
      this.items = dataArr
    },
  },
}
</script>
