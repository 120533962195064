<template>
  <div>
    <b-row>
      <b-col
        md="12"
        sm="12"
      >
        <b-card>

          <b-form

            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col md="4">
                <b-form-group
                  :label="$t('Hedef Yılı')"
                  label-for="target_year"
                >

                  <b-form-select
                    v-model="filterData.target_year"
                    :options="targetYears"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">  <!-- Satış Temsilcisi -->
                <b-form-group
                  v-if="usersData && $can('read','report_sales')"
                  :label="$t('Satış Temsilcisi')"
                  label-for="user_id"
                >
                  <v-select
                    id="user_id"
                    v-model="filterData.user_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usersData"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user_id"
                  />
                </b-form-group></b-col>
            </b-row>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="fetchData"
              >
                Filtrele
              </b-button>
            </div>
          </b-form>

        </b-card>

      </b-col>
      <b-col
        v-if="reportData"
        md="12"
      >

        <b-row>
          <b-col
            v-if="(typeof(reportData) !=='undefined')"
            md="12"
          >
            <sales-target-chart
              :data="reportData.sales_target_graph"

              title="Toplam Hedef ve Satış Grafiği"
            />
          </b-col>
          <b-col md="12">
            <monthly-sales-target-report
              :data="reportData.sales_target_graph"
              :month-target="targetDetailMonth"
              :target-year="filterData.target_year"
              title="Hedef ve Satış Karşılaştırması"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div />
  </div></template>

<script>
import {
  BCard, BRow, BCol, BButton, BForm, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import salesTargetChart from './SalesTargetChart.vue'
import monthlySalesTargetReport from './MonthlySalesTargetReport.vue'

import reportsStoreModule from '../reportsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    monthlySalesTargetReport,
    salesTargetChart,
    BFormSelect,
    BButton,
    BForm,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filterData: {
        user_id: 0,
        target_year: 2015,
        target_month: 0,
        type: '',
      },
      targetDetailMonth: -1,
      reportData: null,

    }
  },
  computed: {
    targetMonts() {
      const arr = [{ number: 0, name: 'Tümü' }]
      this.$staticParams.monthNames.forEach(val => {
        arr.push(val)
      })
      return arr
    },

  },

  created() {
    const d = new Date()
    const thisYear = d.getFullYear()

    this.filterData.target_year = thisYear
    if (this.$route.query.month) {
      // eslint-disable-next-line radix
      this.filterData.target_month = parseInt(this.$route.query.month)
      // eslint-disable-next-line radix
      this.targetDetailMonth = parseInt(this.$route.query.month)
    }
    if (this.$route.query.year) {
      // eslint-disable-next-line radix
      this.filterData.target_year = parseInt(this.$route.query.year)
      this.targetDetailMonth = 0
    }
    if (this.$route.query.type) {
      if (this.$route.query.type === 'current') {
        this.targetDetailMonth = 13
      }
    } else {
      this.filterData.type = ''
    }
    this.fetchData()
  },

  methods: {
    fetchData() {
      store
        .dispatch('app-reports/fetchTargetReports', this.filterData)
        .then(response => {
          this.reportData = response.data.data
        })
        .catch(() => {
        })
    },

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // eslint-disable-next-line no-unused-vars
    const targetsData = ref(null)
    const targetYear = ref(2015)
    const targetYearStart = ref(2015)
    const targetYears = ref([])
    const d = new Date()
    const thisYear = d.getFullYear()

    targetYear.value = thisYear
    const arrTarget = []
    // eslint-disable-next-line no-plusplus
    for (let i = targetYearStart.value; i <= thisYear + 1; i++) {
      arrTarget.push({ text: i, value: i })
    }
    targetYears.value = arrTarget

    const usersData = ref(null)
    store
      .dispatch('app/fetchUsers', {
        list_type: 'all',
      })
      .then(response => {
        const { users } = response.data.data
        const arr = []
        users.forEach(val => {
          arr.push({ label: `${val.name} (${val.email})`, value: val.id })
        })
        arr.unshift({ label: 'Tümü', value: 0 })
        usersData.value = arr
      })
      .catch(() => {
      })

    return {

      // Filter
      avatarText,
      usersData,
      targetYears,
      targetYear,
      // UI
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
