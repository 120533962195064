<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title
          v-if="targetMonth>0"
          class="mb-1"
        >
          {{ targetYear }} Yılı {{ targetMonth }}. Ay Satış Tablosu
        </b-card-title>
        <b-card-title
          v-else
          class="mb-1"
        >
          {{ targetYear }} Yılı Tüm Aylar Satış tablosu
        </b-card-title>
        <b-card-sub-title />
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1" />

      </div>
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <sales-detail-table-all
        :target-year="targetYear"
        :target-month="targetMonth"
        :userid="userid"
        type="all"
        title="Tüm satışlar"
      />
      <top-buyer-categories
        :startdate="startdate"
        :enddate="enddate"
        :userid="userid"
        title="Kategori Bazlı Satış Tablosu"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'

import SalesDetailTableAll from './SalesDetailTableAll.vue'

import TopBuyerCategories from '../sales-reports/TopBuyerCategories.vue'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    SalesDetailTableAll,
    TopBuyerCategories,
  },
  props: {
    userid: {
      type: Number,
      required: true,
    },
    targetYear: {
      type: Number,
      default: 0,
    },
    targetMonth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      startdate: null,
      enddate: null,
      pageLength: 10,
      dir: false,
      isLoading: false,
      sort: 'desc',
      totalRows: 0,
      order_column: 'document_date',
      pages: ['10', '30', '50'],
      page: 1,
      columns: [
        {
          label: 'Tarih',
          field: 'document_date',
        },
        {
          label: 'Temsilci',
          field: 'representative_name',
        },
        {
          label: 'Müşteri',
          field: 'customer_name',
        },
        {
          label: 'Ürün',
          field: 'product_name',
        },
        {
          label: 'Birim Fiyatı',
          field: 'price_currency_converted',
        },
        {
          label: 'Adet',
          field: 'piece',
        },
        {
          label: 'Toplam Fiyat',
          field: 'hareket_tutar',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

  },
  watch: {
    targetYear() {
      this.setStartYearMonth()
    },
    targetMonth() {
      this.setStartYearMonth()
    },
  },
  created() {
    this.setStartYearMonth()
  },
  methods: {
    setStartYearMonth() {
      const lastday = function (y, m) {
        return new Date(y, m, 0).getDate()
      }

      if (this.targetMonth > 0) {
        const lastDayOfMonth = lastday(this.targetYear, this.targetMonth)
        this.startdate = `${this.targetYear}-${this.targetMonth}-01`
        this.enddate = `${this.targetYear}-${this.targetMonth}-${lastDayOfMonth}`
      } else {
        this.startdate = `${this.targetYear}-1-01`
        this.enddate = `${this.targetYear}-12-31`
      }
    },
  },

}
</script>
